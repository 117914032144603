body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{color: #4c4cf0 }
a{color: #4f4fcc }
a{color: #4f4fcc }
a{color: #4f4fcc }
a{color: #4f4fcc }
a{color: #4f4fcc }
a{color: #4D4DFF }
a{color: #5A5AFF }
a{color: #6767FF }
a{color: #7474FF }
a{color: #8181FF }
a{color: #8E8EFF }
a{color: #9B9BFF }
a{color: #A8A8FF }
a{color: #B5B5FF }
a{color: #C2C2FF }
a{color: #CFCFFF }
a{color: #DCDCFF }
a{color: #E9E9FF }
a{color: #F6F6FF }
a{color: #FFDADA }
a{color: #FFC7C7 }
a{color: #FFB4B4 }
a{color: #FFA1A1 }
a{color: #FF8E8E }
a{color: #FF7B7B }
a{color: #FF6868 }
a{color: #FF5555 }
a{color: #FF4242 }
a{color: #FF2F2F }
a{color: #FF1C1C }