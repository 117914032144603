.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.top_menu_button{
  background:unset;
  border: none;
  color:#282c34;
  font-size: 32px;;
}

.top_menu_button:hover,
.top_menu_button:active
{
  background:unset!important;
  border: none!important;
  color:#000;
}
.top_menu_card{
  cursor: pointer;
  margin-top: 8px;
  display:flex;
  flex-direction:row;
}

.padgap{
  height:10px;
}

.coolbar{
  justify-content: space-between;
}

.zone_title{
  font-weight: 600;
  line-height: 2;
  text-align: left;
}
.controls{
  text-align: left;
  display: inline-block;
  float:left;
}

table.cool  {
  width: 100%;
}

table.cool  {
  display: table;
  border-color: gray;
}

.div_timeline>div{
  display: inline-block;
}

#hours{
  border-top: 1px solid #aaaaaa;
  color: #aaaaaa
}

#day_display td {
  height: 18px;
  min-width: 8px;
  margin: 2px;
  background-color: #e4e4e4;
}


.flexbetween{
  justify-content: space-between;
}

.fullwidth{
  width: 100%;
  box-sizing: border-box;
}


.ib{
  display: inline-block;
}


.timeline_div>.timeslot_0{
  border-radius:10px 0px 0px 10px;
}
.timeline_div>.time_slot_last{
  border-radius:0px 10px 10px 0px;
}

.timeslot_0.time_slot_last{
  border-radius:10px 10px 10px 10px;
}

.timeline_div>div{
  box-shadow: inset 0 0 2px #1f1f1fa6;
  margin-top: 4px;
  margin-bottom: 4px;
}


.day_timeline{
  display: inline-block;
  width:50px;
}

.flex{
  display: flex;
}


.day_timeline{
  font-weight: 600;
  text-align: center;
  
}
.current_time_line {
  position: absolute;
  left: 0;
  box-sizing: border-box;
  width: 2px; /* Adjust the width of the line as needed */
  height:25px;
  background-color: #4d4d4d; /* Adjust the color of the line as needed */
}


.col_ON{
  background-color: #ff8b8b;
}

.col_OFF{
  background-color: #f1f1f1;
}

.clickable{
  cursor:pointer;
}

.hoverblue:hover{
  color: #0d6efd;
}

.hoverred:hover{
  color: #fd0d0d;
}

.grey{
  color:#918f8f;
}

.zonetags{
  text-align: left;
  padding-bottom:4px; 
}